import type { NextRouter } from 'next/router'

import type { ReferenceWeb, VideoProps } from '@knauf-group/ct-designs/utils/types'
import { isValidUrl } from '@knauf-group/ct-designs/utils/utils'
import type { EventPayload } from '@knauf-group/ct-shared-nextjs/web/analytics'

import type {
  CmsVideoEventPayload,
  LabelContentUnion,
  RichTextNestedLabelContent,
  VideoEventInputProps,
  VideoEventOutputProps,
  WithCmsComponent,
} from './types'

export const getPageSlugFromRouterPath = (router: NextRouter): string => {
  if (!router) {
    return ''
  }

  const pathName = router.asPath.split('/').slice(-1).join()
  const pathNameWithoutQuery = pathName.split('?').shift()
  const isHomePage = pathNameWithoutQuery === router.locale || pathNameWithoutQuery === ''

  return isHomePage ? 'homepage' : pathNameWithoutQuery
}

export const isContactLink = (reference: ReferenceWeb) =>
  reference?.href?.startsWith('mailto:') || reference?.href?.startsWith('tel:')
export const isDownloadLink = (reference: ReferenceWeb) => {
  if (!isValidUrl(reference?.href)) {
    return false
  }

  const pathName = new URL(reference.href).pathname

  return pathName.startsWith('/api/download-center/')
}

const isRichTextNestedLabelContent = (
  labelContent: LabelContentUnion,
): labelContent is RichTextNestedLabelContent =>
  typeof labelContent === 'object' && Object.hasOwn(labelContent || {}, 'props')

export const normalizeLabelFromReactNode = (labelContent: LabelContentUnion) => {
  if (Array.isArray(labelContent) && isRichTextNestedLabelContent(labelContent[0])) {
    const { children } = labelContent[0].props
    return children.toString()
  }

  return labelContent?.toString()
}

export const getVideoAnalyticsProps = (
  { url, description }: Pick<VideoProps, 'url' | 'description'>,
  globalAnalyticsProps: WithCmsComponent & Pick<EventPayload, 'page_name'>,
): CmsVideoEventPayload => ({
  ...globalAnalyticsProps,
  video_title: description?.substring?.(0, 60) || '',
  video_url: url || '',
})

export const getVideoEventFormatted =
  (
    analyticsProps: CmsVideoEventPayload,
    event: (eventProps: VideoEventInputProps) => void,
    arrayIndex?: number,
  ) =>
  (eventProps: VideoEventOutputProps) =>
    event({ ...eventProps, customProps: analyticsProps, arrayIndex })
