import type { Dispatch, RefObject, SetStateAction } from 'react'
import { useState } from 'react'
import type { NextRouter } from 'next/router'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { EXIT_PREVIEW_URL } from '@cms/utils/constants'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'

const EXIT_PREVIEW_ROUTE = '/api/exit-preview'

const onClickExitPreview = async (
  router: NextRouter,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    setLoading(true)
    const res = await fetch(EXIT_PREVIEW_ROUTE)
    if (res.ok) {
      await router.push(`${EXIT_PREVIEW_URL}/${router.locale}${router.asPath}`)
    } else {
      logger.error(`Could not fetch exit-preview route. Status ${res.status}`)
    }
  } catch (e) {
    logger.error(e)
  }
  setLoading(false)
}

type PreviewAlertProps = {
  ref: RefObject<HTMLDivElement>
}

const PreviewAlert = ({ ref }: PreviewAlertProps) => {
  const { t } = useTranslation('cms', { keyPrefix: 'common.alerts' })
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  // Added toolbar to use the empty space left by using fixed position
  return (
    <>
      <Alert
        variant="filled"
        severity="warning"
        sx={{ position: 'fixed', top: 0, zIndex: 1, width: '100%' }}
        action={
          <Button
            disabled={loading}
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => onClickExitPreview(router, setLoading)}
          >
            {t('previewAlertExitPreviewButtonLabel')}
          </Button>
        }
        ref={ref}
        data-cy="preview-alert-banner"
      >
        <AlertTitle>{t('previewAlertHeading')}</AlertTitle>
        {t('previewAlertText')}
      </Alert>
      <Toolbar sx={{ height: `${ref?.current ? ref.current.clientHeight : 0}px` }} />
    </>
  )
}

export default PreviewAlert
